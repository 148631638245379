html, body {
	width:100%;
	height:100%;
}

body {
	background-color: #c0c0c0;
	margin:0;
	padding:0;
	background-repeat:no-repeat;
	background-size: cover;
	background-position:center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction:column;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: 16px;
}

.logo img {
	max-height: 180px;
}

.content {
	padding: 1em 2em;
	background-color: rgba(255,255,255,.8);
	text-align: center;

	.small {
		font-size: 0.8em;
		padding:0;
		margin:0;
	}
}
